

/*----------------Colors-----------------*/



*[class*="--red"]{
  color: red;
}

*[class*="--green"]{
  color: green;
}

*[class*="--yellow"]{
  color: yellow
}


/*----------------Конец спец-классов------------------*/

