.notification {
  transform: none;
}

.Toastify {
  .Toastify__toast-container {
    width: 410px;
    max-width: 100%;
  }
  .Toastify__toast-theme--dark {
    background: linear-gradient(
      96.96deg,
      rgba(157, 231, 189, 0.4) 21.34%,
      rgba(54, 205, 119, 0.4) 119.18%
    );
    backdrop-filter: blur(20px);
    .notification__text {
      color: #fff;
    }
  }
  .Toastify__toast {
    padding: 15px;
    border-radius: 8px;
  }

  .Toastify__close-button {
    color: #23c381;
  }

  .notification__title {
    color: #23c381;
  }
}
