@import "_reset.scss";
@import "ui-kit.scss";
@import "_ui-change.scss";

:root {
    --main-color: #1A27B7;
    --main-color-hover: #3947E3;

    --accent-color: #1D92A6;
    --acccent-color-hover: #198091;

    --disable-bg: #ECEEEF;


    --link-color: #1A27B7;

    --text-color-main: #191C1F;
    --text-color2: #65717D;
    --text-color3: #A1AAB3;
    --placeholder-color: #A1AAB3; 
    --button-text-reverse: #fff;


    --background-color: #FFFFFF;

    --background-color-dark: #191C1F;
    --border-color: #E6E7E8;
    --border-color2: #E7E9EA;
    --border-color3: #D8DBDF;


    --red-color: #D33031;

}


body {
    //width: 1920px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 180%;
    color: var(--text-color-main);

    &.no-scroll {
        overflow-y: hidden !important;
    }

    button {
        font-family: 'Inter', sans-serif;
        line-height: 0.5;
    }

    a {
        color: var(--main-color-text);
    }

    input,
    textarea {
        font-family: 'Inter', sans-serif;

        &::placeholder {
            font-family: 'Inter', sans-serif;
        }
    }

    * {
        box-sizing: border-box;
    }
}

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: hidden;
}

main {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}


.container {
    width: 1198px;
    max-width: 100%;
    padding: 0 15px;
    text-align: center;
    margin: 0 auto;
    box-sizing: border-box;

    &--type2 {
        width: 1585px;
    }
}

.usn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.flex {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    &--gap4 {
        gap: 4px;
    }

    &--gap8 {
        gap: 8px;
    }

    &--gap12 {
        gap: 12px;
    }

    &--gap16 {
        gap: 16px;
    }

    &--gap24 {
        gap: 24px;
    }
}

.flex-m {
    align-items: center;
}

.flex-t {
    align-items: flex-start;
}

.flex-b {
    align-items: flex-end;
}

.flex-sb {
    justify-content: space-between;
}

.flex-c {
    justify-content: center;
}

.flex-sa {
    justify-content: space-around;
}

.flex-e {
    justify-content: flex-end;
}

.flex-ww {
    flex-wrap: wrap;
}

.mob {
    display: none;
}

section {
    box-sizing: border-box;
    padding: 1px 0;
}

img {
    display: block;
}

/*base*/

.header {
    display: flex;
    align-items: center;
    height: 81px;
    padding: 0 42px;
    position: relative;
    border-bottom: 1px solid var(--border-color);

    &--type2 {
        padding: 0 32px;
        height: 60px;
        border: none;
        background: var(--background-color-dark);

        .nav-block {
            width: calc(100% - 25px);
            left: unset;
            right: 0;
            padding-left: 25px;
            background: var(--background-color-dark);
        }

        .main-nav {
            gap: 16px;
        }

        .nav-item {
            &--lang {
                background: #252A2E;

                .nav-item {
                    &__link {
                        @media screen and (max-width:991px) { 
                            color: #fff;
                        }
                    }
                    &__link-arrow {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }

            &__link {
                color: #fff;
                @media screen and (max-width:991px) { 
                    color: #fff;
                }
            }
            &__link-arrow svg path {
                fill: #fff;
            }
            &.active, &:hover {
                .nav-item__link {
                    color: #fff;
                }
                .nav-item__link-arrow svg path {
                    fill: #fff;
                }
            }
        }


        .dropdown {
            color: #fff;
            border-color: #252A2E;
            background: #252A2E;           
            &--nav {
                @media screen and (max-width:991px) { 
                    padding: 16px;
                    border-radius: 12px;
                }
                
            }
        }

        .dropdown-list {
            >li {
                >a {
                    &:hover {
                        background: var(--background-color-dark);
                    }
                }
            }
        }

        .account-select {
            &__current {
                background: #252A2E;
            }

            &__current-value {
                color: #fff;
            }

            &__current-arrow {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }
        .nav-btn__open path {
            stroke: #fff;
        }
        .nav-btn__close path {
            fill: #fff;
        }
        @media screen and (max-width:991px) {
            .nav-block {

            }
        }
    }

    @media screen and (max-width: 1199px) {
        padding: 0 24px;
    }

    @media screen and (max-width:991px) {
        height: 60px;
        padding: 0 16px;
    }
}

.nav-btn {
    display: none;
    margin-left: auto;
    width: 24px;

    &__open {
        svg path {
            stroke: var(--text-color-main);
        }
    }

    &__close {
        svg path {
            fill: var(--text-color-main);
        }
    }

    @media screen and (max-width: 991px) {
        display: flex;

        &__close {
            display: none;
        }

        &.active {
            .nav-btn__close {
                display: flex;
            }

            .nav-btn__open {
                display: none;
            }
        }
    }
}

.nav-block {
    display: flex;
    margin: 0 auto;
    padding-left: 40px;
    margin-left: 40px;
    border-left: 1px solid var(--border-color);
    flex: 1;

    @media screen and (max-width:991px) {
        display: none;
        position: fixed;
        top: 60px;
        left: 0;
        height: calc(100% - 60px);
        width: 100%;
        margin: 0;
        background: #fff;
        border: none;
        overflow: auto;
        z-index: 999;
        padding: 24px 15px;

        &.active {
            display: block;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;

        @media screen and (max-width:991px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.main-nav {
    display: flex;
    align-items: center;
    gap: 16px 45px;

    @media screen and (max-width:991px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.nav-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 0;
    z-index: 3;

    @media screen and (max-width:991px) {
        flex-wrap: wrap;
        width: 100%;
    }

    &__link {
        display: flex;
        align-items: center;
        gap: 13px;
        font-weight: 500;
        font-size: 14px;
        transition: all 0.2s linear;
    }

    &__link-arrow {
        display: flex;
        width: 12px;
    }

    &--lang {
        background: #F7F8F9;
        border-radius: 8px;
        padding: 8px;

        .nav-item__link {
            gap: 8px;
            font-weight: 600;
        }
    }

    &.active {
        z-index: 10;

        .dropdown {
            display: flex;
        }

        .nav-item__link {
            color: var(--link-color);
        }

        .nav-item__link-arrow {
            transform: rotate(180deg);

            svg path {
                fill: var(--link-color);
            }
        }
    }

    &:hover {
        .nav-item__link {
            color: var(--link-color);
        }
    }
}

.dropdown {
    display: none;
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #F7F8F9;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);
    border-radius: 12px;

    &--nav {
        width: max-content;
        top: calc(100% + 8px);
        left: -20px;

        @media screen and (max-width:991px) {
            position: relative;
            top: unset;
            width: 100%;
            left: 0;
            margin-top: 16px;
            padding-left: 24px;
            box-shadow: none;
            border: none;
            border-radius: 0;

            .dropdown-list {
                display: grid;
                grid-template-columns: 1fr;
                gap: 16px;

                >li {

                    >a,
                    >button {
                        padding: 0;
                        background: none;

                        &:hover,
                        &:active {
                            color: var(--link-color);
                        }
                    }
                }
            }
        }
    }

    &--lang {
        width: max-content;
        top: calc(100% + 32px);
        left: unset;
        right: 0;

        @media screen and (max-width:991px) {
            left: 0;
            right: unset;
            top: calc(100% + 8px);
        }
    }

    &--account {
        width: 207px;
        top: calc(100% + 28px);
        left: 0;

        .dropdown-list>li>a,
        .dropdown-list>li>button {
            padding: 8px 16px;
        }

        @media screen and (max-width:991px) {

            top: calc(100% + 8px);
            width: 100%;
            min-width: 210px;

        }
    }
}

.dropdown-list {
    >li {

        >a,
        >button {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 12px 16px;
            color: var(--text-secondary-color);
            transition: 0.2s;
            font-weight: 500;

            &:hover {
                background: #F7F8F9;
            }
        }

        &:first-child {

            >a,
            >button {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
        }

        &:last-child {

            >a,
            >button {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }
}


.dropdown-icon {
    display: flex;
    width: 25px;
    height: 20px;
}

.drop-icon {
    display: flex;
    align-items: center;
    width: 16px;
}

.drop-demo {
    display: flex;
    padding: 3px 4px 2px 4px;
    background: #D9F8DE;
    border-radius: 3px;
    color: #23C381;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
}

.drop-extra-text {
    margin-left: auto;
    color: var(--text-color2);
}

.nav-right {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 16px 12px;

    @media screen and (max-width:991px) {
        margin-left: 0;
        flex-direction: column;
        align-items: flex-start;
    }
}

.authorization-btns {
    display: flex;
    align-items: center;
    gap: 16px 28px;

    .button {
        font-size: 14px;
    }
}

.authorization {
    position: relative;
    max-width: 430px;
    width: 100%;
    margin: 0 auto;
    z-index: 2;

    &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 160%;
        text-align: left;

        @media screen and (max-width: 991px) {
            font-size: 24px;
        }
    }

    &__details {
        text-align: left;
        margin-top: 12px;
        color: var(--text-color2);

        a {
            color: var(--link-color);
            text-decoration: none;
            font-weight: 400;

            &:hover {
                color: var(--main-color);
            }
        }
    }

    &__form {
        margin-top: 24px;
    }

    &__options {
        display: flex;
        margin-top: 16px;
    }

    &__password-info {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid var(--border-color);
        color: var(--text-color2);

        p {
            position: relative;
            display: flex;
            align-items: baseline;
            gap: 12px;
            font-size: 12px;

            span {
                display: flex;
                width: 10px;
                font-size: 18px;
            }

        }
    }
}


.lang {
    &--settings {
        .nav-item {
            padding: 0;
            background: none;
            border-radius: 0;
        }

        .dropdown--lang {
            top: calc(100% + 8px);
            right: unset;
            z-index: 3;
            left: 0;
        }
    }
}

.link {
    color: var(--link-color);
    text-decoration: none;

    &:hover {
        color: var(--main-color);
    }

    &--fs-16 {
        font-size: 16px;
    }
}


.input-notification {
    margin-top: 8px;

    &--error {
        color: var(--red-color);
    }

    a {
        color: var(--link-color);
    }
}

.form-submit {
    display: flex;
    gap: 16px;
    margin-top: 24px;

    &--center {
        justify-content: center;
    }
}

.back-page {
    margin-bottom: 24px;

    a {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;
        font-size: 16px;
        color: var(--accent-color);
        transition: all 0.2s linear;

        &:hover {
            color: var(--acccent-color-hover);

            .back-page__icon svg path {
                fill: var(--acccent-color-hover);
            }
        }
    }

    &__icon {
        display: flex;

        svg path {
            transition: all 0.2s linear;
        }
    }
}

.form-captcha {
    display: flex;
    width: 302px;
}

.circle-load {
    display: flex;
    position: relative;
    animation: circle-load-rotate 1s linear infinite;
}


.popup-block {
    margin-top: 24px;
}

.form-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin: 0 auto 8px auto;
    border-radius: 50%;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    &--error {
        background: rgba(211, 48, 49, 0.15);

        .form-icon__item {
            background: #D33031;
        }
    }

    &--success {
        background: rgba(35, 195, 129, 0.15);

        .form-icon__item {
            background: #23C381;
        }
    }
}

.signup-bonus {
    text-align: center;
    color: var(--main-color);

    &__value {
        font-weight: 600;
        font-size: 40px;
        line-height: 140%;
    }
}

@keyframes circle-load-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.account-select {
    position: relative;

    &__current {
        display: flex;
        align-items: center;
        height: 39px;
        min-width: 170px;
        gap: 4px;
        background: #F7F8F9;
        border-radius: 8px;
        padding: 8px;
        font-size: 12px;
        font-weight: 500;
    }

    &__current-icon {
        display: flex;
        width: 16px;
    }

    &__current-status {
        padding: 2px 4px;
        display: inline-block;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #23C381;
        background: #002606;
        border-radius: 3px;
    }

    &__current-value {
        color: var(--text-color2);
        font-weight: 400;
    }

    &__current-value-currency {
        color: var(--text-color2);
    }

    &__current-arrow {
        display: flex;
        margin-left: auto;
        width: 19px;
        height: 19px;
        align-items: center;
        justify-content: center;
    }

    &.active {
        z-index: 10;

        .dropdown {
            display: block;

        }
    }

}

.setting-section {
    background: #F8F9FC;
}

.page-wrap {
    display: grid;
    grid-template-columns: 290px minmax(100px, 1fr);
    @media screen and (max-width: 1300px) {
        grid-template-columns: 250px minmax(100px, 1fr);
    }
    @media screen and (max-width: 1220px) {
        grid-template-columns: minmax(100px, 1fr);
    }
}

.sidebar-nav {
    padding: 24px 0;
    background: var(--background-color);

    @media screen and (max-width: 1220px) {
        padding-top: 16px;
    }

    &__more {
        display: none;
        justify-content: center;
        margin-top: 8px;

        @media screen and (max-width: 1220px) {
            display: flex;
        }
    }
}

.sidebar-nav-item {
    padding: 0 30px;

    @media screen and (max-width:1300px) {
        padding: 0 24px;

    }

    &.active {
        .sidebar-nav-item__button-arrow {
            transform: rotate(0);
        }

        .sidebar-nav-item__list {
            display: block;
        }
    }

    &__button {
        padding: 22px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;

        &:hover {
            color: var(--main-color);

            svg {
                path {
                    fill: var(--main-color);
                }
            }
        }
    }

    &__button-arrow {
        min-width: 12px;
        width: 12px;
        transform: rotate(180deg);
        transition: 0.3s;

        svg {
            width: 100%;

            path {
                transition: 0.3s;
            }
        }
    }

    &__list {
        display: none;

        ul {
            li {
                a {
                    display: flex;
                    align-items: center;
                    padding: 12px 0;
                    font-weight: 500;
                    font-size: 14px;
                    color: var(--text-color2);

                    &:hover,
                    &.active {
                        color: var(--main-color);

                        .sidebar-nav-item__icon {
                            svg {
                                path {
                                    fill: var(--main-color);
                                }

                                &.stroke {
                                    path {
                                        fill: transparent;
                                        stroke: var(--main-color);
                                    }
                                }
                            }
                        }
                    }

                    @media screen and (max-width:1220px) {
                        padding: 8px 0;

                    }

                    &.padding-bottom-8 {
                        padding-bottom: 8px;
                    }

                    &.sidebar-nav-item__list-level2 {
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
                }
            }
        }
    }

    &__icon {
        margin-right: 15px;
        width: 24px;
        min-width: 24px;
        height: 24px;

        svg {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            path {
                transition: 0.3s;
            }
        }
    }

    &--hide-mob {
        @media screen and (max-width:1220px) {
            display: none !important;

        }
    }

}

.setting-content {
    padding: 24px;
    margin: 24px;
    background: var(--background-color);

    @media screen and (max-width:1220px) {
        margin: 24px 0;
    }
}

.setting-content-header {
    margin-bottom: 34px;

    &__title {
        font-weight: 500;
        font-size: 32px;

        &--type2 {
            font-weight: 600;
            font-size: 28px;
        }
    }
}

.setting-content-body-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.setting-content-body-item {
    background: var(--background-color);
    box-sizing: border-box;

    &+& {
        margin-top: 24px;
    }
}

.setting-content-body {
    display: grid;
    grid-template-columns: 403px 440px;
    gap: 30px 50px;
    @media screen and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);       
    }
    &--type2 {
        grid-template-columns: 630px;
        @media screen and (max-width: 991px) {
            grid-template-columns: repeat(1, 1fr);           
        }
    }

    &--type3 {
        grid-template-columns: repeat(1, 1fr) !important;        
    }
    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
}


.setting-content-body-item-header {
    margin-bottom: 24px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid var(--border-color);

    &--type2 {
        padding-bottom: 0;
        border: none;
    }

    &__icon {
        margin-right: 16px;
        width: 24px;
        min-width: 24px;

        svg {
            width: 100%;
            height: auto;
        }
    }
}


.setting-content-action {
    @media screen and (max-width: 991px) {
        margin-top: 24px;
    }
}

.setting-user-data-item {
    padding: 0 8px 12px 8px;
    display: grid;
    grid-template-columns: 121px 1fr;
    gap: 24px;

    &:last-child {
        padding-bottom: 0;
    }

    &+& {
        margin-top: 24px;

        @media screen and (max-width:767px) {
            margin-top: 12px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        color: var(--text-color2);
    }

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;

        .button {
            height: 34px;
            font-size: 12px;
        }
    }

    @media screen and (max-width:767px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
        padding: 0 0 13px 0;
    }

}

/*-----Footer----*/

.footer-container {
    padding: 24px 42px;
    border-top: 1px solid var(--border-color);

    @media screen and (max-width: 1199px) {
        padding: 24px;
    }

    @media screen and (max-width:991px) {
        padding: 16px;
    }
    .footer-logo {
      width: 222px;
    }
}

.footer-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media screen and (max-width:475px) {
        flex-direction: column;
    }
}

.copyright {
    text-align: right;
    color: var(--text-color2);
    font-weight: 400;
    font-size: 12px;

    @media screen and (max-width:475px) {
        text-align: center;
    }
}

.footer-nav {
    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 16px 32px;

        @media screen and (max-width:475px) {
            justify-content: center;
            gap: 16px;
        }

        >li {

            >a,
            >button {
                color: var(--text-color-main);
                font-weight: 500;
                transition: all 0.2s linear;

                &:hover {
                    color: var(--link-color);
                }
            }
        }
    }
}


.deposit-content {
    padding: 24px;

    @media screen and (max-width: 720px) {
        padding: 12px;
    }
}

.deposit-crypto-info {
  padding: 20px;
  background-color: #E6F7FF;
  border: 1px solid #96D7FF;
  border-radius: 10px;
  margin-bottom: 10px;

  .btn-crypto-copy {
    margin-top: 10px;
  }
}

.deposit-content-inside {
    height: 100%;
    padding: 24px;
    padding-bottom: 96px;
    border-radius: 12px;
    background: var(--background-color);

    @media screen and (max-width: 720px) {
        padding: 12px;
    }

    >div {
        margin-left: auto;
        margin-right: auto;
        max-width: 548px;
        @media screen and (max-width: 720px) {
            max-width: 100%;
        }
    }
}

.deposit-content-header-left {
    display: flex;
    gap: 24px;
    align-items: center;

    @media screen and (max-width: 720px) {
        gap: 12px;
    }
}

.deposit-content-header-right {
    display: flex;
    gap: 24px;
    align-items: center;

    @media screen and (max-width: 720px) {
        margin-top: 12px;
        gap: 12px;
        width: 100%;
        justify-content: flex-end;
    }
}

.deposit-content-header {
    margin-bottom: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 28px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;

    @media screen and (max-width: 720px) {
        flex-wrap: wrap;
        margin-bottom: 24px;
        font-size: 22px;
    }

    &__back {
        min-width: 41px;
        width: 41px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border-color3);
        border-radius: 8px;

        @media screen and (max-width: 720px) {
            min-width: 34px;
            width: 34px;
            height: 34px;
        }
    }
}


.deposit-content-item {
    &+& {
        margin-top: 16px;
    }

    &__title {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        color: var(--text-color2);
    }

    .select-block {
        margin-bottom: 16px;
    }
}

.deposit-hint {
    margin: 16px 0;
    padding-top: 16px;
    border-top: 1px solid var(--border-color);
    font-size: 12px;
    color: var(--text-color2);
}

.deposit-payment {
    display: flex;
    align-items: center;
    gap: 8px;
}


.deposit-checking {
    display: flex;    
    width: calc(100% + 48px);
    margin-top: -24px;
    margin-bottom: 24px;
    margin-left: -24px !important;
    max-width: unset !important;
    gap: 8px 24px;
    padding: 16px 24px;
    background: #FFF2F2;
    border-radius: 12px 12px 0px 0px;
    &__icon {
        display: flex;
        align-items: flex-start;
        width: 30px;
    }
    &__info {
        flex: 1 0 0;
        @media screen and (max-width: 720px) {
           width: calc(100% - 54px);
           flex: unset;
        }
    }
    &__info-title {
        font-weight: 600;
        font-size: 16px;
    }
    &__info-text {
        font-weight: 500;
        font-size: 14px;
    }
    .button {
        margin: auto 0;
        @media screen and (max-width: 720px) {
            margin-left: 54px;
        }
    }
    @media screen and (max-width: 720px) {
        width: calc(100% + 24px);
        margin-top: -12px;
        margin-left: -12px !important;
        flex-wrap: wrap;
    }
}

.deposit-address {
    margin-top: 16px;
    &__value {
        color: var(--main-color);
        font-weight: 500;
        font-size: 16px;
        word-wrap: break-word;
    }
    &__copy {
        margin-top: 16px;
    }
}

.deposit-timer {
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    &__value {
        display: flex;
        align-items: center;
        gap: 4px;
        >p {
            font-size: 12px;
            color: var(--text-color2);
        }
    }
    &__value-icon {
        display:inline-flex;   
        width: 16px;
    }
    .tooltip {
        font-weight: 400;
    }
}

.circle-load {
    display: flex;
    position: relative;
    animation: circle-load-rotate 1s linear infinite;
    svg path {
        transition: all 0.2s linear;
    }
}

@keyframes circle-load-rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.deposit-qr {
    display: flex;
    width: 182px;
    padding: 8px 0;
    img {
        width: 192px;
    }
}

.withdraw-to {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    gap: 24px;

    &__item {
        p {
            margin-bottom: 8px;
            font-weight: 400;
            font-size: 14px;
            color: var(--text-color2);
        }

        span {
            display: block;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.receive-amount {
    margin: 16px 0;

    p {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 24px;
        line-height: 160%;

        @media screen and (max-width: 720px) {
            font-size: 20px;
        }

        span {
            font-weight: 500;
            font-size: 18px;
            line-height: 180%;
            color: var(--text-color2);

            @media screen and (max-width: 720px) {
                font-size: 16px;
            }
        }
    }

    >span {
        display: block;
        font-size: 14px;
        color: var(--text-color2);
    }
}


.withdrawal-info-pop {
    margin-bottom: 16px;
    padding: 16px;
    background: #F7F8F9;
    border-radius: 8px;

    &--type2 {
        padding: 0;
        background: none;
    }

    &--type3 {
        >div {
            display: flex;
            justify-content: space-between;
            gap: 16px;

            &+div {
                margin-top: 12px;
            }
        }

        p {
            display: block !important;

            &+p {
                margin-top: 0 !important;
            }

            &:nth-child(2) {
                width: 105px;
            }
        }
    }

    &--type4 {
        >div {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            &+div {
                margin-top: 12px;
            }
        }

        p {
            &:nth-child(2) {
                width: auto;
            }
        }
    }

    &--type5 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px;

        @media screen and (max-width: 520px) {
            grid-template-columns: repeat(2, 1fr) !important;

            * {
                text-align: left;
            }
        }

        p {
            display: block !important;

            &+p {
                margin-top: 0 !important;
            }

            &:nth-child(2) {
                width: 105px;
            }
        }
    }

    p {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        color: var(--text-color2);
        text-align: left;

        &+p {
            margin-top: 12px;
        }

        >span {
            &:last-child {
                text-align: right;
                font-weight: 500;
                color: var(--text-color-main);
                word-break: break-word;
            }
        }
    }
}

.withdrawal-info-list-pop {
    margin-bottom: 16px;

    ul {
        li {
            position: relative;
            padding-left: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;

            &+li {
                margin-top: 16px;
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                width: 4px;
                height: 4px;
                border-radius: 100%;
                transform: translateY(-50%);
                background: #03BC70;
            }
        }
    }
}

.select-network {
    margin: 16px 0;

    &__item {
        padding: 16px;
        width: 100%;
        display: block;
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        background: #F7F8F9;
        border-radius: 8px;
        line-height: 1.8;

        &+& {
            margin-top: 16px;
        }

        span:nth-child(2) {
            display: block;
            color: var(--text-color2);
            font-size: 14px;
            font-weight: 400;
        }
    }
}



.currency {
    display: flex;
    align-items: center;
    gap: 8px;
    &__icon {       
        width: 24px;
        height: 24px;
        min-width: 24px;
        img,
        svg {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    &__name {
        font-weight: 500;
        color: var(--text-color-main);
        font-size: 16px;        
        &--type2 {
            font-size: 20px;
        }
    }

}

.content {
    //max-width: 1150px;
    padding: 24px;
    @media screen and (max-width: 1300px) {
        padding: 16px;
    }
    @media screen and (max-width: 720px) {
        padding: 12px;
    }
}

.content-inside {
    height: 100%;
    padding: 24px;
    padding-bottom: 96px;
    border-radius: 12px;
    background: var(--background-color);

    &--type2 {
        height: auto;
        padding-bottom: 24px;
    }

    @media screen and (max-width: 720px) {
        padding: 12px;
        .content-inside {
            &--type2 {
                padding-bottom: 12px;
            }
        }
    }
}

.content-title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 28px;
    line-height: 160%;
    color: var(--text-color-main);

    &--fz24 {
        font-size: 24px;
    }

    @media screen and (max-width: 720px) {
        font-size: 22px;
    }
}


.td-hidden-name {
    display: none;

    @media screen and (max-width: 1220px) {
        display: block;
    }
}


.table-filter {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    >div {
        margin-bottom: 0;
    }

    @media screen and (max-width: 960px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        >div {
            width: 100%;
            max-width: 100%;
        }
    }

    @media screen and (max-width: 560px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}



.table {
    font-size: 14px;
    text-align: left;
    border: none;
    overflow: auto;
    scrollbar-color: transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: var(--border-color3);
        cursor: pointer;
    }
    @media screen and (max-width: 1199px) {
       
        .table-header {
            display: none;
        }

        .td-hidden-name {
            display: block;
        }

        .tr {
            align-items: flex-start;
            grid-template-columns: repeat(3, 1fr) !important;
            border-bottom: 1px solid var(--border-color3) !important;
            padding: 6px 0;

            .td--center,
            .td--right {
                align-items: flex-start !important;
                text-align: left !important;
            }
        }
    }

    @media screen and (max-width: 991px) {
        .tr {
            grid-template-columns: repeat(3, 1fr) !important;
        }
    }

    @media screen and (max-width: 560px) {
        .tr {
            grid-template-columns: repeat(2, 1fr) !important;
        }
    }

    &--type2 {
        .table-header {
            .td {
                position: relative;
                padding-right: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background: #E6E7E8;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &--size2 {
        .table-header {
            * {
                font-size: 12px;
            }
        }
    }
    &--size3 {
        * {
            font-size: 12px;
        }
    }

    &--black {
        .table-header {
            .td {
                color: #65717D;

                &::before {
                    background: #101213;
                }
            }
        }

        .table-body {
            .td {
                color: #FBFBFB;
            }
        }
    }

    &--trade-history {
        & .tr {
            grid-template-columns: 1.35fr .88fr .91fr 1.07fr .95fr 1.05fr .95fr .9fr .84fr .85fr .8fr;
        }

        .table-header {
            .td {
                position: relative;
                padding-right: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background: #E6E7E8;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &--open-orders {
        & .tr {
            grid-template-columns: 1.25fr .83fr .64fr .83fr .5fr .5fr .8fr .99fr .82fr .8fr .82fr .75fr 1fr;
        }
        @media screen and (max-width: 1360px) {
            .table-header, .table-body {
                min-width: 1200px;
            }
        }
        @media screen and (max-width: 1199px) {
            .table-header, .table-body {
                min-width: unset;
            }
        }
        .table-header {
            .td {
                position: relative;
                padding-right: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background: #E6E7E8;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &--pending-orders {
        & .tr {
            grid-template-columns: 1.37fr 1fr .82fr 1.3fr 1.25fr 1.25fr 1.5fr 1fr 1.2fr;
        }

        .table-header {
            .td {
                position: relative;
                padding-right: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background: #E6E7E8;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &--order-history {
        overflow: auto;
        & .tr {
            grid-template-columns: .7fr .95fr 1.25fr 1.1fr .65fr .67fr .8fr .8fr 1.1fr .75fr .90fr .85fr;
        }
        @media screen and (max-width: 1360px) {
            .table-header, .table-body {
                min-width: 1200px;
            }
        }
        @media screen and (max-width: 1199px) {
            .table-header, .table-body {
                min-width: unset;
            }
        }

        .table-header {
            .td {
                position: relative;
                padding-right: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background: #E6E7E8;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &--market {        
        scrollbar-color: transparent;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: #373947;
            cursor: pointer;
        }

        .table-header {
            height: 45px;

            .tr {
                border: none;
            }
        }



        .table-body {
            height: calc(100% - 45px);
            overflow-y: auto;

            @media screen and (max-width: 1219px) {
                height: auto;
                max-height: 500px;
            }
            .tr {               
               
                border: none !important;
                @media screen and (max-width: 1380px) {
                    //padding-left: 10px;
                    //padding-right: 10px;
                }
                .td {
                    &:first-child {
                        padding-left: 10px;
                        @media screen and (max-width: 1380px) {
                            //padding-left: 0;
                        }
                    }
                    &:last-child {
                        padding-right: 10px;
                        @media screen and (max-width: 1380px) {
                            //padding-right: 0;
                        }
                        
                    }
                }
                &.active,
                &:first-child {
                    display: grid;
                }

                &.active {
                    background: #060708;
                }

                &.active:first-child {
                    background: #101213;
                    border-radius: 8px 8px 0px 0px;
                }

                &.active:last-child {
                    border-radius: 0px 0px 8px 8px;
                }
            }

            .tr-child {
                .td {
                    color: #65717D;
                }
            }
        }
        .tr .td--right {
            justify-content: flex-end !important;
            text-align: right !important;
            @media screen and (max-width: 1199px) {
                justify-content: flex-end !important;
                text-align: right !important;
                align-items: flex-end !important;
            }
        }
        @media screen and (max-width: 1199px) {
            .table-header {
                display: block !important;    
                height: auto ;           
                .tr {
                    border: none !important;
                }
                .td {                    
                    line-height: 1.6;                    
                    padding-bottom: 0;

                }
            }
            .td-hidden-name {
                display: none !important;
            }
        }
    }

    &--market-open-positions {
        & .tr {           
            grid-template-columns: 2.5fr 1fr .75fr  .85fr .85fr 1.25fr 1.25fr .8fr .85fr .85fr .85fr 1.15fr !important;
        }
        .table-header, .table-body {
            min-width: 1400px;            
        }

    }

    &--market-pending-orders {
        & .tr {
            grid-template-columns: 2.4fr 1.2fr 1.1fr 1.7fr 1.7fr 1.55fr 2fr 1.35fr 1fr !important;
        }
        .table-header, .table-body {
            min-width: 1000px;            
        }
    }

    &--market-history-orders {
        & .tr {
            //grid-template-columns: 1.1fr 1.15fr 1.3fr .8fr .55fr 1.15fr .75fr .7fr .8fr 1.4fr .85fr .9fr .75fr 1fr .8fr !important;
            grid-template-columns:  1fr 0.9fr 1.3fr .8fr .55fr 1fr .8fr 0.8fr 1.1fr .9fr .75fr .8fr !important;
        }
        .table-header, .table-body {
            min-width: 1400px;            
        }
    }

    &--market-trading-history {
        & .tr {
            grid-template-columns: 1.6fr 0.9fr .9fr 1fr 1fr 1fr 1fr 1fr 0.9fr 0.5fr  !important;
        }
        .table-header, .table-body {
            min-width: 1100px;            
        }
    }

    &--account-table1 {
        & .tr {
            grid-template-columns: 2.25fr 1.45fr 1.45fr 1.9fr 1.5fr 2fr;
        }
        .table-header {
            .td {
                position: relative;
                padding-right: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background: #E6E7E8;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &--account-table2 {
        & .tr {
            grid-template-columns: 1.75fr .85fr .85fr 1.45fr 1.3fr 1.3fr 1.1fr 1.95fr;
        }
    }
    &--with-devider {
        .table-header {
            .td {
                position: relative;
                padding-right: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background: #E6E7E8;
                }

                &:last-child {
                    padding-right: 0;
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    &--account-table-deposit {
        & .tr {
            grid-template-columns: minmax(120px,0.7fr) 1fr 0.8fr 0.9fr 1fr minmax(120px,0.5fr) minmax(130px,0.6fr) 0.7fr;
            gap: 0 8px;
        }
    }
    &--account-table-withdraw {
        & .tr {
            grid-template-columns: 0.8fr 1fr  0.5fr 1fr 1fr 0.8fr 1fr minmax(100px, 0.8fr);
            gap: 0 8px;
        }
    }
    &--account-table-transactions{
        & .tr {
            grid-template-columns:200px  200px 200px ;
            justify-content: space-between;
            gap: 0 8px;
        }
    }

    &--referral-my-rewards {
        & .tr {
            grid-template-columns: 3.5fr 3.2fr 2.1fr 2.2fr;
        }
        .table-header {
            .tr {
                border: none;
            }
        }
    }

    &--referral-my-referrals {
        & .tr {
            grid-template-columns: 3.5fr 2.1fr 2.2fr;
        }
        .table-header {
            .tr {
                border: none;
            }
        }
    }

    &--referral-transaction-history {
        & .tr {
            grid-template-columns: 3.2fr 3.2fr 2.1fr 2.2fr;
        }
        .table-header {
            .tr {
                border: none;
            }
        }
    }

    &--cash-operation {
        & .tr {
            grid-template-columns: 1.2fr 1.7fr 1.35fr 1.4fr 1.6fr 2.2fr 1.1fr;
        }
        .table-header {
            .td {
                position: relative;
                padding-right: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background: #E6E7E8;
                }

                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }

        @media screen and (max-width: 991px) {
            .tr {
                grid-template-columns: repeat(3, 1fr) !important;
            }
        }
    
        @media screen and (max-width: 560px) {
            .tr {
                grid-template-columns: repeat(2, 1fr) !important;
            }
        }
        
    }

}

// 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px

.td {
    &--center {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &--right {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .select__current {
            text-align: right;
            justify-content: flex-end;
        }
    }
}

.table-header {
    .tr {
        border: none;
        border-bottom: 1px solid #E6E7E8;
    }

    .td {
        padding: 8px 0;
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
        color: #5F5F8B;
    }
}

.table-body {
    .tr {
        border: none;
    }

    .td {
        padding: 10px 0;
        line-height: 1.8;
        @media screen and (max-width: 1199px) {
            padding: 4px 0;
        }
    }
}


.td-text-small {
    display: block;
    font-weight: 400;
    font-size: 12px;
    color: var(--text-color2);
}


.symbol-info {
    display: inline-block;
    font-weight: 400;
    font-size: 8px;
    vertical-align: middle;
}


.pagination-block {
    display: flex;
    justify-content: flex-end;

    &--center {
        justify-content: center;
    }

    &--left {
        justify-content: flex-start;
    }
}


.value-text-icon {
    padding: 2px 8px;
    height: 26px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    color: #5A66E8;
    background: #E6E8FB;
    border-radius: 4px;
    vertical-align: middle;
}

.popup-position-information {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        color: #65717D;
    }

    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 180%;
        color: var(--text-color-main);

        .fz-12 {
            font-size: 12px;
            color: #65717D;
        }
    }

    &--type2 {
        display: grid;
        gap: 12px;
        grid-template-columns: repeat(4, 1fr);

        @media screen and (max-width: 520px) {
            grid-template-columns: repeat(2, 1fr) !important;

            * {
                text-align: left;
            }
        }
    }
}

.popup-button {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}


.input-line {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.main {
    &--dark {
        background: #252A2E;
    }
}

.market-top-line {
    margin: 5px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 4px 16px;
    background: var(--background-color-dark);
    border-radius: 12px;

    @media screen and (max-width: 1219px) {
        flex-wrap: wrap;
    }

    .tooltip-item {
        display: flex;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: 400;
            font-size: 11px;
            color: #FBFBFB;

            >span {
                color: #23C381;
               
            }
        }
    }
   
}

.grey {
    color: var(--text-color2);
}

.market-section {
    padding: 0 5px;
    padding-bottom: 5px;
    //min-height: calc(100vh - 111px);
    //min-height: 810px;
    height: calc(100vh - 111px);
    min-height: 1200px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (max-width: 1219px) {
        height: auto;
        min-height: unset;
    }
}

.market {
    height: 565px;
    display: flex;
    gap: 5px;

    >div {
        height: 100%;

    }

    @media screen and (max-width: 1219px) {
        flex-wrap: wrap;
        height: auto;
    }
}

.market-schedule {
    width: calc(100% - (320px + 348px + 5px + 5px));

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
    }

    @media screen and (max-width: 1219px) {
        width: 100%;
        height: 465px !important;
    }
}

.market-list-wrap {
    padding: 8px;
    display: flex;
    width: 320px;
    max-width: 100%;
    flex-direction: column;
    gap: 8px;
    border-radius: 12px;
    background: var(--background-color-dark);

    @media screen and (max-width: 1219px) {
        width: calc(50% - 2.5px);
        height: 566px !important;
    }

    @media screen and (max-width: 760px) {
        width: 100%;
        height: 400px !important;
    }
}

.market-list-currency {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;

    &__item {
        padding: 5px 8px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
        color: #65717D;
        border-radius: 4px;

        &.active,
        &:hover {
            color: #fff;
            background: #32383E;
        }
    }
}

.market-list-search {
    .input {
        margin-bottom: 0 !important;
    }
    .input-item {
        border-color: #32383E;
    }
}


.market-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: calc(100% - 82px);

    &__header {
        height: 22px;

        .market-list {
            &__item {
                color: #65717D;
            }
        }
    }

    &__body {
        height: calc(100% - 30px);
        overflow-y: auto;

        scrollbar-color: transparent;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: #373947;
            cursor: pointer;
        }

        &--plug {
            min-height: 437px;

            .plug {
                margin-top: 120px;
            }
        }
    }

    &__item {
        display: grid;
        grid-template-columns: 1.1fr 1fr 1fr;
        gap: 8px;
        font-size: 12px;
        color: #FBFBFB;

        &.active {
            padding: 8px;
            background: #060708;
            border-radius: 8px;
        }

        p {
            display: flex;
            align-items: center;
            gap: 2px;

            span {
                font-size: 8px;
                color: #65717D;
            }

            &:last-child {
                justify-content: flex-end;
                text-align: right;
            }
        }
    }
}

.market-order-form-wrap {
    
    display: flex;
    width: 348px;
    max-width: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    padding: 8px;
    border-radius: 12px;
    background: var(--background-color-dark);

    @media screen and (max-width: 1219px) {
        //width: calc(50% - 2.5px);
        flex: 1;
        height: auto !important;

    }

    @media screen and (max-width: 760px) {
        width: 100%;
    }
}


.market-order-form-top {
    height: calc(100% - 49px);
}


.market-order-form-button {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}


.market-order-form-nav {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 4px;
}


.market-order-form {
    height: calc(100% - 50px);

    &__scroll {
        height: calc(100% - 55px);
        overflow-y: auto;

        scrollbar-color: transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: #373947;
            cursor: pointer;
        }
    }
}


.market-order-form-title {
    margin-bottom: 5px;

    p {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 600;
        font-size: 16px;
        color: #FBFBFB;

        span {
            padding: 2px 4px;
            display: block;
            font-weight: 700;
            font-size: 10px;
            line-height: 105%;
            color: #5A66E8;
            background: #2D315B;
            border-radius: 4px;
        }
    }

    strong {
        display: block;
        font-weight: 400;
        font-size: 12px;
        color: #65717D;
    }
}


.market-table {
    //min-height: 350px;
    flex: 1;
    max-height: calc(100% - 570px);
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: var(--background-color-dark);

    @media screen and (max-width: 1219px) {
        height: auto;
        flex: unset;
    }

    .table {
        //height: calc(100% - 34px);
        min-height: 300px;
        overflow: auto;
        
        @media screen and (max-width: 1219px) {
            height: auto;
        }

        &--height-type2 {
            height: calc(100% - 82px);

            @media screen and (max-width: 1219px) {
                height: auto;
            }
        }
        .table-body {
            scrollbar-color: transparent;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                background: #373947;
                cursor: pointer;
            }
        }
    }
}

.market-table-nav {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 1219px) {
        margin-bottom: 12px;
    }
}

.position-info {
    display: flex;
    align-items: center;
    gap: 8px;

    .symbol-info {
        color: #65717D;
    }

    &__number {
        padding: 1px 12px;
        font-weight: 600;
        font-size: 12px;
        color: #FBFBFB;
        border: 1px solid #32383E;
        border-radius: 50px;
    }

    &__status {
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        color: #FBFBFB;
        border-radius: 4px;
        background: #BAB677;
    }
}


.popup-tool-information {
    .value-text-icon {
        color: #5A66E8;
        background: #2D315B;
    }

    .popup-text {
        font-size: 14px;

        * {
            color: #65717D;
        }
    }

    .withdrawal-info-pop {
        background: #15171b;
    }

    .withdrawal-info-pop p {
        color: #65717D;
    }

    .withdrawal-info-pop p>span:last-child {
        color: #FBFBFB;

    }

    .popup-position-information {
        &__title {
            * {
                color: #65717D;
            }
        }
    }
}

.popup-position-information-title {
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 180%;
    color: #FBFBFB;
}

.popup-button,
.popup-submit {
    .button {
        min-width: auto !important;
    }
}


.table-body--plug {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.plug {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        color: #FBFBFB;

        a {
            font-weight: 700;
            color: #1D92A6;
        }
    }
}

.popup-top-button {
    display: flex;
    gap: 16px;
    transform: translateY(-35px);
}


.content-title-wrap {
    margin-bottom: 34px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    .content-title {
        margin-bottom: 0 !important;
    }
}

.content-title-button {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media screen and (max-width: 760px) {
        width: 100%;
        flex-wrap: wrap;
    }
}



.balance-info {
    display: flex;
    gap: 36px;
    @media screen and (max-width: 920px) {
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 520px) {
        grid-template-columns: repeat(1, 1fr);
    }
    &__title {
        margin-bottom: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        color: var(--text-color2);
    }
    &__counter {
        display: flex;
        align-items: flex-end;
        font-weight: 500;
        font-size: 24px;
        line-height: 160%;

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            color: var(--text-color2);
        }
    }
}

.table-filter-wrap {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 16px;
    .table-filter {
        margin-bottom: 0 !important;
    }

    @media screen and (max-width: 760px) {
        flex-wrap: wrap;
    }
}

.table-filter-button {
    display: flex;
    gap: 16px;

    @media screen and (max-width: 760px) {
        width: 100%;
        flex-wrap: wrap;
    }
}


.copy-button {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color2);
    word-break: break-word;
    svg {
        min-width: 15px;
    }
    &--center {
        justify-content: center;
    }
    &--type2 {
        font-size: 18px;
        font-weight: 600;
        color: #1A27B7;
        svg {
            min-width: 25px;
        }
        @media screen and (max-width: 520px) {
            font-size: 14px;
        }
    }
}


.account-referral-section {
    padding-top: 65px;
    padding-bottom: 97px;
    background: url(../images/account-referral-section-bg.png) no-repeat center top 60px, #F8F9FC;
}



.account-referral-title {
    margin: 0 auto;
    margin-bottom: 16px;
    width: 610px;
    max-width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 160%;
    text-align: center;
    color: var(--text-color-main);

    @media screen and (max-width: 520px) {
        font-size: 18px;
    }
}


.account-referral-statistics {
    margin: 36px auto;
}


.account-referral-statistics-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media screen and (max-width: 980px) {
        margin: 0 auto;
        max-width: 560px;
        grid-template-columns: repeat(1, 1fr);
        
    }

    &__item-title {
        margin-bottom: 14px;
        padding: 0 12px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #1D92A6;
        border-radius: 12px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #191C1F;

    }

    &__item-info {
        height: 120px;
        background: #FFFFFF;
        border-radius: 12px;
    }

    &__item-info-counter {
        width: 180px;
        height: 68px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: #1D92A6;
        border: 10px solid #F5F7FB;
        font-weight: 500;
        font-size: 24px;
        text-align: center;
        color: #FBFBFB;
    }
}

.account-referral-table {
    margin-top: 60px;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 12px;
}

.notifications-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__item {
        padding-bottom: 24px;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        border-bottom: 1px solid #E6E7E8;
    }
    &__item-title {
        font-weight: 600;
        font-size: 16px;
    }
}


.email-sent-icon {
    margin: 0 auto;
    img {
        margin: 0 auto;
    }
}

.email-sent-text {
    margin: 24px auto;
    width: 450px;
    max-width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
    text-align: center;
    color: #191C1F;
}

.email-sent-repeat {
    display: flex;
    justify-content: center;
    &__button {
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        color: #1A27B7;
        cursor: pointer;
    }
}

.authorization-confirm {
    text-align: center;
    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 160%;
        text-align: center;
    }
    &__text {
        margin: 24px auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        color: #191C1F;
    }
}

.verify-doc {
    margin-top: 32px;
    &__title {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 10px;
        color: var(--placeholder-color);
        font-weight: 500;
        .tooltip {
            padding: 20px;
        }
    }
}

.field-hint {
    margin-top: 10px;
    font-size: 12px;
    color: rgba(101,113,125, 0.5);
}

.upload-doc-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    @media screen and (max-width: 720px) {
      flex-direction: column;
      .upload-action {
        width: 100%;
      }
    }
}

.upload-action {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    gap: 10px;
    padding: 0 20px;
    border: 1px solid var(--border-color3);
    border-radius: 12px;
    font-size: 14px;
    color: var(--text-color2);
    overflow: hidden;
    cursor: pointer;
    &:hover {
        border-color: var(--text-color-main);
    }
    &__icon {
        display: flex;
        flex: 0 0 24px;
    }
    &__file {
        position: absolute;
        top: 0;left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}

.uploaded-doc-list {
    margin-top: 24px;
    max-width: 282px;
}

.doc-file {
    display: flex;
    align-items: center;
    gap: 10px;
    &__photo {
        display: flex;
        width: 32px;
    }
    &__name {
        color: var(--text-color2);
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
    }
    &+.doc-file {
        margin-top: 16px;
    }
}

.doc-actions {
    display: flex;
    gap: 10px;
    margin-left: auto;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        height: 26px;
        border-radius: 6px;
        border: 1px solid #E6E8EC;
        background: #F7F8F9;
        color: var(--text-color2);
        font-size: 12px;
        font-weight: 500;
        
    }
    &__close {
        width: 26px;
        padding: 0 !important;
        svg {
            width: 9px;
        }
    }
}

.tooltip-info {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--text-color3);
    &__icon {
        display: flex;
        flex: 0 0 16px;
    }
    &+.tooltip-info {
        margin-top: 16px;
    }
}

.green {
    color: #23C381 !important;
}

.red {
    color: #D33031 !important;
}

.yellow {
    color: #F2994A !important;
}

.green2 {
    color: #1D92A6 !important;
}

.grey {
    color: #65717D ;
}

.fw-700 {
    font-weight: 700;
}




/*----------------Спец-классы для обнуления, font-weight и остального------------------*/

/*----------------Margin, padding, width-----------------*/

*[class*="--mt-0"]{
    margin-top: 0;
}

*[class*="--mt-5"]{
    margin-top: 5px;
}

*[class*="--mt-10"]{
    margin-top: 10px;
}

*[class*="--mt-15"]{
    margin-top: 15px;
}

*[class*="--mt-20"]{
    margin-top: 20px;
}

*[class*="--mt-25"]{
    margin-top: 25px;
}

*[class*="--mt-30"]{
    margin-top: 30px;
}

*[class*="--mt-35"]{
    margin-top: 35px;
}

*[class*="--mt-40"]{
    margin-top: 40px !important;
}

*[class*="--mt-45"]{
    margin-top: 45px !important;
}

*[class*="--mt-50"]{
    margin-top: 50px !important;
}

*[class*="--mt-4"]{
    margin-top: 4px;
}

*[class*="--mt-8"]{
    margin-top: 8px;
}

*[class*="--mt-12"]{
    margin-top: 12px;
}

*[class*="--mt-16"]{
    margin-top: 16px;
}

*[class*="--mt-24"]{
    margin-top: 24px;
}

*[class*="--mt-32"]{
    margin-top: 32px;
}

*[class*="--mt-48"]{
    margin-top: 48px;
}

*[class*="--mb-0"]{
    margin-bottom: 0;
}

*[class*="--mb-5"]{
    margin-bottom: 5px;
}

*[class*="--mb-15"]{
    margin-bottom: 15px;
}

*[class*="--mb-20"]{
    margin-bottom: 20px;
}

*[class*="--mb-25"]{
    margin-bottom: 25px;
}

*[class*="--mb-30"]{
    margin-bottom: 30px;
}

*[class*="--mb-35"]{
    margin-bottom: 35px;
}

*[class*="--mb-40"]{
    margin-bottom: 40px !important;
} 

*[class*="--mb-45"]{
    margin-bottom: 45px;
}

*[class*="--mb-50"]{
    margin-bottom: 50px !important;
}

*[class*="--mb-4"]{
    margin-bottom: 4px;
}

*[class*="--mb-8"]{
    margin-bottom: 8px;
}

*[class*="--mb-12"]{
    margin-bottom: 12px;
}

*[class*="--mb-16"]{
    margin-bottom: 16px;
}

*[class*="--mb-24"]{
    margin-bottom: 24px;
}

*[class*="--mb-32"]{
    margin-bottom: 32px;
}

*[class*="--mb-48"]{
    margin-bottom: 48px;
}

*[class*="--ml-0"]{
    margin-left: 0;
}

*[class*="--mr-0"]{
    margin-right: 0;
}

*[class*="--p-0"]{
    padding: 0;
}

*[class*="--pt-0"]{
    padding-top: 0;
}

*[class*="--pb-0"]{
    padding-bottom: 0;
}

*[class*="--pl-0"]{
    padding-left: 0;
}

*[class*="--pr-0"]{
    padding-right: 0;
}

*[class*="--width-100"]{
    width: 100%;
}

*[class*="--width-50"]{
    width: 50%;
}

/*----------------GAPS-----------------*/

*[class*="--gap-5"]{
    gap: 5px;
}

*[class*="--gap-10"]{
    gap: 10px;
}

*[class*="--gap-15"]{
    gap: 15px;
}

*[class*="--gap-20"]{
    gap: 20px;
}

*[class*="--gap-25"]{
    gap: 25px;
}

*[class*="--gap-30"]{
    gap: 30px;
}

*[class*="--gap-35"]{
    gap: 35px;
}

*[class*="--gap-40"]{
    gap: 40px !important;
}

*[class*="--gap-45"]{
    gap: 45px;
}

*[class*="--gap-50"]{
    gap: 50px !important;
}

*[class*="--gap-4"]{
    gap: 4px;
}

*[class*="--gap-8"]{
    gap: 8px;
}

*[class*="--gap-12"]{
    gap: 12px;
}

*[class*="--gap-16"]{
    gap: 16px;
}

*[class*="--gap-24"]{
    gap: 24px;
}

*[class*="--gap-32"]{
    gap: 32px;
}

*[class*="--gap-48"]{
    gap: 48px;
}

/*----------------Borders-----------------*/

*[class*="--border-none"]{
    border: none;
}

*[class*="--border-radius-50"]{
    border-radius: 50%;
}

/*----------------Font-weight-----------------*/

*[class*="--fw-300"]{
    font-weight: 300;
}

*[class*="--fw-400"]{
    font-weight: 400;
}

*[class*="--fw-500"]{
    font-weight: 500;
}

*[class*="--fw-600"]{
    font-weight: 600;
}

*[class*="--fw-700"]{
    font-weight: 700;
}

*[class*="--fw-800"]{
    font-weight: 800;
}

*[class*="--fw-900"]{
    font-weight: 900;
}


/*----------------Text-align----------------*/

*[class*="--ta-l"]{ 
    text-align: left; 
} 
 
*[class*="--ta-c"]{ 
    text-align: center; 
} 
 
*[class*="--ta-r"]{ 
    text-align: right; 
}

.no-chart-data {
    color: #b6b6b6;
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    height: 100%;
}