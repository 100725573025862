.button {
    height: 41px;
    min-width: 162px;
    padding: 0 24px;
    gap: 8px;
    background: var(--main-color);
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 180%;
    transition: all 0.2s linear;
    &:hover {
        background: var(--main-color-hover);
    }
    &--gap16 {
        gap: 16px;
    }

    &--loaded {
        background: #7B85EC !important;
    }

    &:disabled {
        color: #A1AAB3;
        background: var(--disable-bg);
        opacity: 1;
    }

    &--type2 {
        background: var(--accent-color);

        &:hover {
            background: var(--acccent-color-hover);
        }
    }

    &--type3 {
        background: transparent;
        color: var(--text-color-main);
        border: 1px solid var(--border-color3);

        &:hover {
            border-color: var(--main-color);
            background: transparent;
        }
    }

    &--type4 {
        background: #23C381;
        color: #fff;
        border: 1px solid #23C381;

        &:hover {
            background: #1ba86d;
            color: #fff;
            border: 1px solid #1ba86d;
        }
    }

    &--type5 {
        background: #D33031;
        color: #fff;
        border: 1px solid #D33031;

        &:hover {
            background: #bb2a2a;
            color: #fff;
            border: 1px solid #bb2a2a;
        }
    }

    &--type6 {
        background: #101213;
        color: #65717D;
        border: 1px solid #101213;

        &:hover {
            background: var(--main-color);
            color: #fff;
            border: 1px solid var(--main-color);
        }
    }
    &--copy-color {
        background: #F7F8F9;
        color: var(--text-color2);
        &:hover {
            background: #F7F8F9;
            color: var(--text-color-main);
            svg path {
                transition: all 0.2s linear;
            }
            svg path {
                fill:  var(--text-color-main);
            }
        }
    }
    &--waiting  {
        background: #F7F7FE;
        color: var(--main-color);
        .circle-load svg path {
            fill: var(--main-color);
        }
        &:hover {
            background: #F7F7FE;
            color: var(--main-color);
            .circle-load svg path {
                fill: var(--main-color);
            }
        }
    }
    &--size2 {
        height: 56px;
        border-radius: 12px;
    }

    &--size3 {
        height: 85px;
        border-radius: 12px;
    }

    &--size4 {
        height: 41px;
        border-radius: 8px;
    }

    &--size4 {
        min-width: 146px;
        padding: 0 8px;
        height: 34px;
        font-size: 12px;
        border-radius: 4px;
    }
    &--size5 {
        height: 34px;
        font-size: 12px;
        border-radius: 4px;
    }
    &--height-40 {
        height: 40px;
        border-radius: 8px;
        font-size: 14px;
    }
    &--text-type {
        height: auto;
        min-width: unset;
        padding: 0;
        border-radius: 0;
        background: none !important;
        color: var(--text-color-main);

        &:hover {
            color: var(--main-color);
        }
    }

    &--type-text {
        font-size: 16px;
        height: auto;
        width: auto;
        min-width: auto;
        padding: 0;
        border: none;
        background: none;
        color: var(--main-color);
        line-height: 1;

        &:hover {
            color: var(--main-color-hover);
            border: none;
            background: none;
        }
    }

    &--type-icon {
        padding: 0;
        min-width: 23px;
        width: 23px;
        height: 23px;
        background: none;
        border: none;
        border-radius: 4px;

        &:hover, &.active {
            background: #32383E;
        }
    }

    &--regular {
        min-width: 200px;
        width: auto;
    }

    &--width-auto {
        min-width: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
    &--width-auto2 {
        min-width: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    &--width-280 {
        max-width: 100%;
        width: 280px;

        @media screen and (max-width: 420px) {
            width: 100%;
        }
    }


    &--width-330 {
        max-width: 100%;
        width: 330px;

        @media screen and (max-width: 420px) {
            width: 100%;
        }
    }

    &--column {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    &--min-width-auto {
        min-width: auto;
    }

    &__extra-text {
        display: block;
        font-size: 14px;
        font-weight: 400;
    }

}


.show-pass {
    width: 20px;
}

.input-notification {
    font-size: 12px;
    color: var(--red-color);

    &--type2 {
        color: var(--text-color2);

        span {
            color: var(--text-color-main);
        }
    }

    &--type3 {
        font-size: 14px;
        color: var(--text-color2);

        span {
            color: var(--text-color-main);
            font-weight: 500;
        }
    }
    &--type4 {
        text-align: right;
        margin: 0 0 8px 0;
    }

    &--fs14 {
        font-size: 14px;
    }
}

.input {
    margin-bottom: 16px;

    &--error {
        .input-item {
            border-color: var(--red-color);
        }
    }

    &__notification {
        font-size: 12px;
        color: var(--red-color);

        &--type2 {
            color: var(--text-color2);

            span {
                color: var(--text-color-main);
            }
        }

        &--type3 {
            font-size: 14px;
            color: var(--text-color2);

            span {
                color: var(--text-color-main);
                font-weight: 500;
            }
        }
        &--type4 {
            text-align: right;
            margin: 0 0 8px 0;
        }

        &--fs14 {
            font-size: 14px;
        }
    }

    &--size2 {
        width: auto;

        .input-item {
            height: 34px;
            border-radius: 4px;
            padding: 0 10px;
            min-width: 240px;
            font-size: 12px;
            color: var(--main-color-text);

            &::placeholder {
                font-size: inherit;
            }
        }
    }

    &--size3 {
        width: auto;

        .input {
            &__name {
                margin-bottom: 4px;
                font-size: 12px;
            }
        }

        .input-item {
            height: 41px;
            border-radius: 4px;
            padding: 0 12px;
            padding-right: 35px;
            font-size: 14px;
            color: var(--main-color-text);

            &::placeholder {
                font-size: inherit;
            }
        }
    }

    &--number {
        .input-item {
            padding: 0 50px;
            text-align: center;
            border-radius: 8px;
        }

        .input-icon {
            width: 25px;
        }
    }

    &--black {
        .input {
            &__name {
                color: #FBFBFB;
            }

            &__notification {
                color: #65717D;
            }
        }

        .input-item {
            color: #FBFBFB;
            border-color: #65717D;
            background: none;

            &:disabled {
                color: #4C555E;
                border: 1px solid #4C555E;
                background: rgba(50, 56, 62, 0.5);
            }
        }

        .input-icon {
            svg {
                path {
                    fill: #F7F8F9;
                }
            }
        }

    }

    &--filter-width2 {
        width: 330px;
    }
    &--disabled {
        .input-icon button{
            svg {
                path {
                    fill: #4C555E;
                }
            }
        }
    }
}


.input-item {
    height: 56px;
    padding: 0 16px;
    border-color: var(--border-color);
    border-radius: 12px;
    color: var(--text-color-main);
    font-weight: 500;
    font-size: 16px;

    &::placeholder {
        font-size: 16px;
        color: var(--placeholder-color);
    }

    &--right-icon {
        padding-right: 50px;
    }

    &--pr25 {
        padding-right: 25px !important;
    }

    &--pr50 {
        padding-right: 50px !important;
    }

    &--pr75 {
        padding-right: 75px !important;
    }

    &--pr100 {
        padding-right: 100px !important;
    }

    &--pr-125 {
        padding-right: 125px !important;
    }
}

.input-icon {
    &--auto {
        width: auto;
    }

    &--width-20 {
        width: 20px;
    }
}

.input-icon-text {
    margin-right: 10px;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: 500;
    color: var(--accent-color);

    &--type2 {
        right: 6px;
    }
}

.input-button {
    font-weight: 700;
    font-size: 16px;
    color: var(--main-color);

    &:hover {
        color: var(--main-color-hover);
    }
}


.textarea {
    border-color: var(--border-color);

    textarea {
        min-height: 140px;
        font-size: 16px;
        font-weight: 500;
        resize: none;
    }

    &::placeholder {
        font-size: 16px;
        color: var(--placeholder-color);
    }
}

.authorization-section {
    position: relative;
    display: flex;
    align-items: center;
    min-height: unset;
    flex: 1;
    padding: 75px 0;
    overflow: hidden;

    @media screen and (max-width: 767px) {
        padding: 24px 16px 32px 16px;
    }

    &__container {}
}

.checkbox {
    &__label {
        gap: 16px;
    }

    &__text {
        margin: 0;
        font-size: 14px;

        a {
            text-decoration: none !important;
            display: inline;
            color: var(--link-color);
        }
    }

    &__item {
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
        border: 1px solid var(--border-color3);
        border-radius: 5px;

    }

    &--black {
        .checkbox {
            &__label {
                gap: 4px;
            }

            &__text {
                color: #FBFBFB;
            }
        }
    }
}


.checkbox input:checked~.checkbox__item {
    background: transparent;
    border-color: var(--accent-color);

    .checkbox__item-icon svg path {
        stroke: var(--accent-color);
    }
}

.popup-window {
    background: rgba(76, 85, 94, 0.4);

    &--black {
        background: rgba(18, 20, 22, 0.9);

        .popup {
            background: #191C1F;

            * {
                color: #fff;
            }

            &__close {
                svg {
                    path {
                        fill: #FBFBFB;
                    }
                }
            }
        }

    }
}



.popup {
    width: 464px;
    background: var(--background-color);
    padding: 24px 32px 32px 32px;

    &--padding-type2 {
        padding: 32px;
    }

    @media screen and (max-width: 991px) {
        padding: 24px;
    }

    &__close {
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        margin-left: auto;
        margin-bottom: 16px;
        width: 18px;

        svg {
            width: 100%;

            path {
                fill: #1C1C29;
            }
        }

        @media screen and (max-width: 991px) {
            margin-bottom: 8px;
        }
    }

    &--width-370 {
        width: 370px;
    }

    &--width-400 {
        width: 400px;
    }

    &--width-440 {
        width: 440px;
    }

    &--width-490 {
        width: 490px;
    }

    &--width-580 {
        width: 580px;
    }

    &--width-690 {
        width: 690px;
    }

    &--small {
        width: 338px;
    }
}

.popup-header {
    &__title {
        font-size: 20px;
        font-weight: 600;

        &--type2 {
            font-size: 16px;
        }
    }

    &__subtitle {
        margin-top: 12px;
        font-size: 14px;
        color: var(--text-color2);
        text-align: center;
    }
}

.popup-body {
    margin-top: 32px;

    &--type2 {}
}

.popup-text {
    font-size: 16px;
    color: var(--text-color2);
    text-align: center;
    line-height: 1.8;

    &--type2 {
        font-size: 14px;
    }

    &--type3 {
        font-size: 12px;
    }

}


.popup-submit {
    margin-top: 32px;
    gap: 16px;
}

.popup-footer {
    margin-top: 32px;

    .popup-submit {
        margin-top: 0;
    }
}




.select-block {
    &__name {
        margin-bottom: 8px;

        &--type2 {
            font-size: 12px;
        }

        &--type3 {
            font-size: 18px;
        }
    }
}

.select {
    height: 56px;
    border-color: var(--border-color);
    border-radius: 12px;
    z-index: auto;


    &__drop {
        z-index: 100;
        border-color: var(--border-color);
        border-radius: 12px;

        ul {
            >li {

                >a,
                >button {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    min-height: 44px;
                    line-height: 1;
                }
            }
        }

        &--checkbox {
            padding: 16px 12px;
            width: 250px;
            background: #FFFFFF;
            border: 1px solid #F7F8F9;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);
            border-radius: 12px;
            .checkbox {
                margin: 0;
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }
        
    }

    &__current {
        font-size: 16px;
        color: var(--text-color-main);
        font-weight: 500;
        line-height: 1.8;

        &--placeholder {
            color: var(--placeholder-color);
        }
    }

    &__current-arrow {
        flex: 0 0 10px;
        max-width: 10px;
    }

    &--type3 {
        height: 34px;
        border-radius: 4px;

        .select {
            &__current {
                padding: 0 10px;
                min-width: 165px;
                font-size: 12px;
                color: var(--main-color-text);
            }
            &__drop {
                ul > li> a, ul > li > button {
                    padding: 6px;
                    min-height: 34px;
                    font-size: 12px;
                }
            }
        }
    }

    &--type4 {
        height: auto;
        border: none;
        background: none;

        .select {
            &__current {
                padding: 0;
                gap: 6px;
                font-size: 14px;
                color: var(--accent-color);
            }

            &__current-arrow {
                margin-left: 0;
                flex: 0 0 8px;
                max-width: 8px;

                path {
                    fill: var(--accent-color) !important;
                }
            }

            &__drop {
                width: 140px;
                right: 0;
                left: auto;
                ul {
                    > li {
                        > a, button {
                            padding: 5px 10px;
                            font-size: 14px;
                            line-height: 180%;
                        }
                    }
                }
            }
        }
    }

    &--type5 {
        height: 32px;
        border-radius: 4px;

        .select {
            &__current {
                padding: 0 10px;
                min-width: 165px;
                font-size: 12px;
                color: var(--main-color-text);
            }
        }
    }
    &--choose-currency {
        
    }
    &--black {
        color: #FBFBFB;
        border-color: #32383E;
        background: none;

        .select {

            &__current-arrow {
                margin-left: 0;
                flex: 0 0 8px;
                max-width: 8px;

                path {
                    fill: #FBFBFB;
                }
            }

            &__drop {
                border-color: #32383E;
                background: #32383E;

                ul {
                    >li {
                        >button {
                            color: #FBFBFB;

                            &:hover {
                                background: #101213;
                            }
                        }
                    }
                }
            }
        }
    }
}

.select-search {
    padding: 10px;
}

.select--currensy {
    position: static;

    .select {
        &__drop {
            padding: 15px;
            top: 100%;
        }

        &__drop-scroll {
            margin-top: 15px;
            padding-right: 5px;
            max-height: 280px;
        }

        &__drop-item {
            ul {
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &:hover,
                    &.active {
                        .select__drop-item-check {
                            opacity: 1;
                        }
                    }

                    button {
                        padding: 0;

                        &:hover {
                            .select-current-currensy__name {
                                color: var(--main-color-text);
                            }

                            background: none;
                        }

                    }

                    &+li {
                        margin-top: 8px;
                    }
                }
            }
        }

        &__drop-item-check {
            opacity: 0;
            transition: 0.3s;
        }
    }

    &-type2 {
        position: relative;

        .input+.select__drop-scroll {
            margin-top: 15px;
        }

        .select__drop-scroll {
            margin-top: 0;
        }

        .select__drop-item {
            ul {
                li {
                    &+li {
                        margin-top: 14px;
                    }
                }
            }
        }
    }

}

.select-current-currensy {
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        min-width: 24px;

        img,
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__name {
        font-weight: 500;
        color: var(--text-color-main);
        font-size: 16px;
        transition: 0.3s;
    }

    &--type2 {
        .select-current-currensy {
            &__name {
                color: var(--text-color-main);
            }

            &__name-text {
                margin-left: 4px;
                color: var(--text-color2);
            }
        }
    }
}



.input-item-info {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color2);

    span {
        color: var(--main-color-text);
    }

    &--type2 {
        font-size: 14px;
    }
}



.pagination {
    >li {

        >a,
        >button {
            min-width: 32px;
            width: 32px;
            height: 32px;
            border: none;
            background: none;
            border-radius: 3px;
        }

        &.arrow {
            &.disabled {

                >a,
                >button {
                    pointer-events: none;
                }

                svg {
                    opacity: 0.3;
                }
            }

        }

        &:not(.disabled) {

            &:hover,
            &.active {

                >a,
                >button {
                    background: var(--main-color);
                    color: #fff;
                }

                .fill path {
                    stroke: #fff;
                }
            }

        }
    }
}


.tooltip-wrap--center-align {
    display: inline-flex;
}

.tooltip-item {
    &__icon {
        display:inline-flex;   
        width: 16px;
    }
}

.tooltip {
    background: #32383E;
    line-height: 1.6;
    &::after {
        border-bottom-color: #32383E;
    }

    &--ta-c {
        text-align: center;
    }

    &--width60px {
        width: 60px;
    }

    &--width100px {
        width: 100px;
    }
    &--width-440 {
        width: 440px;
    }
    &--arrow-none, &--no-arrow {
        &::after {
            display: none;
        }
    }
    &--arrow-top-center:after {
        top: -11px;
    }
   
}


.notification {
    &--icon-successful {
        padding: 24px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 8px;
        background: linear-gradient(96.96deg, rgba(157, 231, 189, 0.4) 21.34%, rgba(54, 205, 119, 0.4) 119.18%);
        backdrop-filter: blur(20px);
        .notification {
            &__close {
                top: 24px;
                right: 24px;
            }
            &__info {
                padding: 0;
            }
            &__title {
                color: #23C381;
            }
            &__text {
                color: #FBFBFB;
            }
            &__icon {
                min-width: 24px;
                width: 24px;
                height: auto;
                background: none;

                svg {
                    width: 24px;
                }
            }
        }
    }
}


.switch {
    &__toggler {
        width: 36px;
        height: 20px;
        border: none;
        &::before {
            width: 14px;
            height: 14px;
        }
    }
}

.switch input:checked ~ .switch__toggler:before {
    left: 18px;
}

.switch--type2 input:checked ~ .switch__toggler {
    background: #1D92A6;
}