.logo--dark {
  display: none;
}

.container--full-width {
  width: 100%;
}

*[class*='--mt-16'] {
  margin-top: 16px;
}

.dropdown-list > li > button {
  width: 100%;
  line-height: inherit;
  font-size: inherit;
}

.account-select__current-status {
  background: #d9f8de;
}

  .logo--white{
    width: 222px;
  }

.header--type2 {
  .logo--dark {
    display: block;
    width: 222px;
  }
  .logo--white {
    display: none;
  }
  .dropdown-list > li > button:hover {
    background: #191c1f;
  }
  .nav-item__link-arrow svg path {
    fill: #fff;
  }
  .account-select__current-status {
    background: #002606;
  }
  .authorization-btns .button {
    color: #fff;
  }
}

.account-select.active .account-select__current-arrow {
  transform: rotate(180deg);
}

.sell,
.popup-window--black .popup .sell {
  color: #d33031;
}

.buy,
.popup-window--black .popup .buy {
  color: #23c381;
}

.input--black + .input__notification {
  color: #65717d;
  margin-bottom: 8px;
}

.color--inherit {
  color: inherit;
}

.cursor--pointer {
  cursor: pointer;
}

.copy-button--type2 {
  svg path {
    fill: #1a27b7;
  }
}

.copy-button {
  cursor: pointer;
}

.success,
.approved {
  color: #23c381;
}

.failed,
.canceled {
  color: rgba(211, 48, 49, 1);
}

.waiting,
.new {
  color: rgba(242, 153, 74, 1);
}

.widget-hide-fullscreen {
  width: 50px;
  height: 36px;
  position: absolute;
  background-color: #131722;
  z-index: 1;
  right: 74px;
  top: 1px;
}
.market-schedule {
  position: relative;
}