.market-list-currency__item {
  text-transform: uppercase;
}

.market-list__item {
  position: relative;
  cursor: default;
}

.bc-settings__wrap {
  justify-content: flex-start;
  align-items: center;
  display: none;
  position: absolute;
  left: 2px;
  top: 2px;
  gap: 20px;
  width: 32px;
  height: calc(100% - 4px);
  padding: 0 6px;
  transition: width 0.3s ease;
}
.bc-settings__wrap svg {
  width: 16px;
  height: 16px;
}
.bc-settings__content {
  display: none;
}
.bc-isauth:hover {
  padding: 8px 8px 8px 32px;
  background: #060708;
  border-radius: 8px;
}
.bc-settings__wrap:hover {
  width: auto;
  border-radius: 6px;
  background: var(--background-color-dark);
}
.bc-settings__wrap:hover .bc-settings__content,
.bc-isauth:hover .bc-settings__wrap {
  display: flex;
}
.bc-btn__fav svg {
  pointer-events: none;
}
.bc-btn__fav.active svg {
  fill: #ffae30;
}

.market-order-form-wrap {
  flex-grow: 1;
}

.plug__icon svg {
  width: 42px;
  height: 42px;
}

.market-order-form-wrap {
  min-width: 348px;
}

.market-order-form-button {
  .button:disabled {
    background: transparent;
  }
}
