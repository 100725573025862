.identity-verification {
  overflow: auto;
  max-height: 100vh;
  width: unset;
  max-width: 580px;
  .react-datepicker-wrapper {
    width: 100%;
  }
  video {
    max-width: 100%;
  }
}

.upload-action--disabled {
  opacity: 0.7;
  &, & > * {
    cursor: default;
  }
  &:hover {
    border-color: var(--border-color3);
  }
}