.popup-overlay {
  background: rgba(76, 85, 94, 0.4);
}

.withdrawal-info-pop--type5 {
  grid-template-columns: repeat(2, 1fr);
}

.popup-position-information-title {
  color: inherit;
}
