.table--group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.table--market-open-positions .tr {
  grid-template-columns: 2.5fr 0.65fr 0.65fr 0.85fr 0.85fr 1.15fr 1.15fr 1fr 1fr 0.8fr 0.8fr 1.15fr;
}
.table--trade-history .tr {
  grid-template-columns: 1.5fr 0.88fr 0.6fr 1.07fr 0.95fr 1.05fr 0.95fr 0.9fr 0.84fr 0.85fr 0.4fr;
}

.table--market-history-orders .tr {
  grid-template-columns: 1.1fr 1.15fr 1.3fr 0.8fr 0.55fr 1fr 0.8fr 1fr 1fr 0.9fr 0.75fr 0.8fr;
}
.table--open-orders .tr {
  grid-template-columns: 1.4fr 0.6fr 0.6fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.6fr 0.7fr 0.85fr;
}
.table--market-trading-history .tr {
  grid-template-columns: 1.8fr 0.8fr 0.8fr 1.4fr 1.4fr 1.4fr 1.4fr 1.4fr 1fr 0.7fr 0.5fr;
}
.table--pending-orders .tr {
  grid-template-columns: 1.37fr 0.8fr 0.8fr 1fr 0.8fr 0.8fr 1.2fr 1.3fr 1fr;
}
.button--type-icon:hover,
.button--type-icon.active {
  background: rgba(50, 56, 62, 0.05);
}

.pagination > li > a,
.pagination > li > button {
  cursor: pointer;
}

.pagination {
  > li {
    .arrow {
      svg {
        width: 10px;
        height: auto;
      }
    }
    &.disabled {
      opacity: 0.3;
    }
  }
}
.market-table {
  .table-body .tr:hover:first-child {
    background: #101213;
  }
  .button--type-icon:hover,
  .button--type-icon.active {
    opacity: 1;
  }
  .pagination-block {
    margin-top: 30px;
  }
  .pagination {
    > li > a,
    > li > button {
      color: #fff;
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.table--open-orders {
  min-height: 250px;
}

.account-referral-statistics-list__item-info-counter {
  width: 220px;
}
